.myInput {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

.myInput + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}
.myInput + label::before {
  content: "";
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.myInput:checked + label::before {
  border-color: #813630;
  background-color: #813630;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.myInput:not(:disabled):not(:checked) + label:hover::before {
  border-color: #8136302a;
}
/* стили для активного состояния чекбокса (при нажатии на него) */
.myInput:not(:disabled):active + label::before {
  background-color: #8136302a;
  border-color: #8136302a;
}
/* стили для чекбокса, находящегося в фокусе */
.myInput:focus:hover + label::before:hover {
  box-shadow: 0 0 0 0.2rem #8136302a;
}
/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.myInput:focus:not(:checked) + label::before {
  border-color: #adb5bd;
}
/* стили для чекбокса, находящегося в состоянии disabled */
.myInput:disabled + label::before {
  background-color: #813630;
}
