@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/Inter-VariableFont_slnt\,wght.ttf');
}

@font-face {
  font-family: 'Rubik';
  src: url('assets/fonts/Rubik-VariableFont_wght.ttf');
}

body {
  margin: 0;
  font-family: "Inter", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

#root {
  height: 100%;
  font-family: "Inter", serif;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

.App {
  height: 100%;
  font-family: "Inter", serif;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

.height-need {
  height: 100%;
  font-family: "Inter", serif;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

img {
  vertical-align: top;
}

html,
body {
  height: 100%;
  font-family: "Montserrat", serif;
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.mobile-header {
  display: none;
  position: fixed;
  width: 100%;
  height: 70px;
  top: 0;
  left: 0;
  background-color: white;
  border-bottom: 1px solid #e3e3e3;
  z-index: 3;
}

@media (max-width: 1023px) {
  .mobile-header {
    display: block;
  }
  
}


.header_burger {
  margin: 26px 0 0 26px;
}

.sidenav {
  height: calc(100% - 20px);
  width: 100px;
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  margin: 10px;
  background-color: #ededed;
  overflow-x: hidden;
  padding: 25px 25px 25px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border-radius: 16px;
  border: solid 1px transparent
}

.main {
  margin-left: 100px; /* Same as the width of the sidenav */
  min-height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
}

.false-big-caledar-icon {
  display: none;
}

@media (max-width: 1023px) {
  .main {
    margin: 10px 10px 10px 10px;
    padding: 10px;
  }
}

.menu-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header_burger {
  position: fixed;
  display: none;
}

.navbar-text {
  display: none;
  text-align: center;
  line-height: 36px;
  font-family: 'Rubik', serif;
}

.navbar-text-small {
  display: none;
  line-height: 26px;
  font-family: 'Rubik', serif;
}

.link-nav-nav-nav {
  display: none !important
}

.navbar-iconic-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  gap: 1.5rem;
  font-size: 24px;
  margin: 0 auto;
}

.pic-logout {
  margin: 0 auto !important;
}

.navbar-icon {
  font-size: 36px;

}

.link-nav {
  -webkit-touch-callout: none; 
  -webkit-user-select: none;   
  -khtml-user-select: none;    
  -moz-user-select: none;      
  -ms-user-select: none;       
  user-select: none;
  text-decoration: none;
  color: #212529;
  padding: 1rem 0;
  background: none;
  transition: ease-in-out 0.3s;
  border-radius: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.link-nav:hover {
  -webkit-tap-highlight-color: transparent;
  background-color: #e3e3e3;
  transition: ease-in-out 0.3s;
}

.link-sub-nav {
  display: none;
  padding: 0.5rem !important;
  font-size: 11px !important
}

.navbar-logo {
  width: 80px;
  height: 80px;
}



@media (max-width: 1023px) {
  /*.sidenav {*/
  /*	width: 75px;*/
  /*	padding: 12px;*/
  /*}*/
  /*.sidenav img {*/
  /*	max-width: 40px;*/
  /*}*/

  /*.main {*/
  /*margin-left: 75px; !* Same as the width of the sidenav *!*/
  /*}*/

  /*.menu-block {*/
  /*gap: 30px;*/
  /*}*/

  .false-big-caledar-icon {
    display: block;
  }

  .true-big-caledar-icon {
    display: none;
  }

  .sidenav {
    left: -100%;
    padding: 25px 25px 25px 25px;
    transition: all 0.5s ease;
    margin: 0;
    border-radius: 0;
    height: 100vh;
    /* height: calc(var(--vh, 1vh) * 100); */
    width: 100vw;
  }

  .sidenav.active {
    height: 100vh;
    /* max-height: calc(var(--vh, 1vh) * 100); */
    width: 100vw;
    position: fixed;
    z-index: 12;
    top: 0;
    left: 0;
    transition: all 0.5s ease;
    background-color: #ededed;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 1rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    border-radius: 0;
  }

  .header_burger {
    display: block;
    position: fixed;
    width: 30px;
    height: 20px;
    z-index: 25;
  }

  .App.lock {
    overflow: hidden;
  }

  .header_burger span {
    position: absolute;
    background-color: #304269;
    left: 0;
    width: 100%;
    height: 2px;
    top: 9px;
    transition: all 0.3s ease 0s;
  }
  .header_burger:before,
  .header_burger:after {
    content: "";
    background-color: #304269;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    transition: all 0.3s ease 0s;
  }
  .header_burger:before {
    top: 0;
  }
  .header_burger:after {
    bottom: 0;
  }

  .header_burger.active span {
    transform: scale(0);

  }
  .header_burger.active:before {
    transform: rotate(45deg);
    top: 9px;
  }
  .header_burger.active:after {
    transform: rotate(-45deg);
    bottom: 9px;
  }

  .main {
    margin: 0px;
  }

  .navbar-text {
    display: block;
  }
  .navbar-text-small {
    font-size: 20px;
    display: block;
  }
  .link-nav-nav {
    display: none;
  }
  .link-nav-nav-nav {
    display: block !important
  }

  .navbar-iconic-block {
    justify-content: center;
    text-align: center;
  }

  .link-sub-nav {
    display: block;
  }

}

@media (max-width: 767px) {
  /* .header_burger {
    margin: 27px 0 0 15px;
  }

  .sidenav {
    width: 60px;
    left: -100%;
    padding: 75px 10px 25px 10px;
    transition: all 0.5s ease 0s;
  }

  .sidenav img {
    max-width: 40px;
  }

  .sidenav.active {
    height: 100%;
    width: 60px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    transition: all 0.5s ease 0s;
    background-color: #304269;
    overflow-x: hidden;
    padding: 75px 10px 25px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }

  .sidenav.active img {
    max-width: 40px;
  } */
}


/* -------- Calendar -------- */

.calendar-page-block {
  display: flex;
  flex-direction: row;
}

/* .calendar-block.wide {
  margin: 0 0 0 10px;
  background-color: #ededed;
  min-height: calc(100vh - 20px);
  width: calc(100vw - 130px);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
} */

.calendar-block.narrow {
  position: fixed;
  margin: 0 0 0 10px;
  background-color: #ededed;
  height: calc(100% - 20px);
  width: calc(100vw - 540px);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
}

.calendar-settings-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.5rem;
}

.left-settings {
  display: flex;
  flex-direction: row;
  gap: 1rem
}

.detailed-event-block.closed {
  height: calc(100% - 20px);
  width: 400px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: -420px;
  margin: 10px;
  background-color: #ededed;
  overflow-x: hidden;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border-radius: 16px;
  border: solid 1px transparent;
  transition: 0.3s;
}

.detailed-event-block.opened {
  height: calc(100% - 20px);
  width: 400px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  margin: 10px;
  background-color: #ededed;
  overflow-x: hidden;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border-radius: 16px;
  border: solid 1px transparent;
  transition: 0.3s;
}

.mobile-calendar-navbar {
  display: none;
}

@media (max-width: 1023px) {
  .calendar-block.narrow {
    position: fixed;
    width: calc(100vw - 20px);
    height: calc(var(--vh, 1vh) * 100 - 90px);
    /* top: 0;
    bottom: 0;
    right: 0;
    left: 0; */
    margin: 70px 0 0 0;
  }
  .mobile-calendar-navbar {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    top: 15px;
    right: 26px;
    overflow: hidden;
    z-index: 7;
    gap: 0.5rem;
  }
  .mobile-header-navigation {
    margin: auto 0;
  }
}


/* -------- Detailed ------------ */

.detailed-event {
  display: flex;
  flex-direction: column;
}

.detailed-closing-block {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.closing-icon {
  color:#212529;
  cursor: pointer;
  font-size: 36px;
}

.detailed-event-body {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.detailed-name {
  font-size: 28px;
  font-weight: 500;
  text-align: left;
  margin-top: -30px;
  margin-right: 30px;
}

.detailed-audience {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.detailed-audience-item {
  padding: 4px;
  background-color: #dbdbdb;
  border-radius: 4px;
}

.detailed-date {
  text-align: left;
}

.detailed-time {
  text-align: left;
}

.detailed-place {
  text-align: left;
}

.detailed-comment {
  text-align: left;
}

.detailed-part-title {
  text-align: left;
}

.detailed-docs-item {
  cursor: pointer;
  padding: 4px;
  background-color: #dbdbdb;
  border-radius: 4px;
}

.detailed-vr {
  text-align: left;
}

.detailed-external {
  text-align: left;
}

.detailed-bold-names {
  font-weight: 500;
}

.detailed-organizer {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  justify-content: left;
}

.detailed-organizer-logo {
  margin: 0 auto;
}

.detailed-organizer img {
  max-height: 60px;
}

.detailed-organizer-name {
  font-weight: 400;
  text-align: center;
}


@media (max-width: 1023px) {
  .detailed-event-block.opened {
    height: 100vh;
    /* height: calc(var(--vh, 1vh) * 100); */
    width: 100vw;
    position: fixed;
    z-index: 35;
    top: 0;
    right: 0;
    background-color: #ededed;
    overflow-x: hidden;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    border: solid 1px transparent;
    transition: 0.3s;
    margin: 0;
    border-radius: 0;
  }
}

/* -------- Filters -------------- */

.filters-block {
  height: calc(100% - 20px);
  width: 400px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  margin: 10px;
  background-color: #ededed;
  overflow-x: hidden;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  border-radius: 16px;
  border: solid 1px transparent;
}

.calendar-type-chosing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.d-w-m-button {
  width: 100px !important;
  padding: 10px !important;
}

.left-right-icons {
  position: relative;
  font-size: 40px;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: 4;
}

.right-settings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mobile-header-today {
  display: flex;
  flex-direction: row;
}

.today-icon {
  font-size: 36px;
  cursor: pointer;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  margin-right: -0.3rem;
  margin-left: -0.2rem;
  padding-top: 5px;
}

.filters-icon {
  display: none;
  font-size: 36px;
  cursor: pointer;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  margin-right: -0.3rem;
  margin-left: 1.2rem;
  padding-top: 5px;
}

.filters-block-mobile {
  display: none;
}

.filters-closing {
  display: none;
}

@media (max-width: 1023px) {
  .filters-block {
    display: none;
  }
  .filters-icon {
    display: block
  }
  .filters-block-mobile.closed {
    height: calc(100% - 20px);
    width: 400px;
    position: fixed;
    z-index: 1;
    top: 0;
    right: -420px;
    margin: 10px;
    background-color: #ededed;
    overflow-x: hidden;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    border-radius: 16px;
    border: solid 1px transparent;
    transition: 0.3s;
  }
  .filters-block-mobile.opened {
    height: 100%;
    width: 100vw;
    position: fixed;
    z-index: 35;
    top: 0;
    right: 0;
    background-color: #ededed;
    overflow-x: hidden;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    border: solid 1px transparent;
    transition: 0.3s;
    margin: 0;
    border-radius: 0;
  }
  .filters-closing {
    display: block;
  }
}

.calendar-filters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.my-events-filter {
  text-align: center;
}

.filters-title {
  font-size: 22px;
  font-weight: 450;
  margin-top: -1rem;

}

.one-filter-block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
}

.one-filter-title {
  font-weight: 450;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filters-submit {
  margin: 1rem 0 0 0;
}

.filters-clear {
  font-size: 16px;
  cursor: pointer;
}

.filters-clear:hover {
  text-decoration: underline;
}

.select-all-comment {
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  color: gray;
  transition: 0.3s;

}

.select-all-comment:hover {
  color: black;
  transition: 0.3s;
}

/* -------- Add Page ------------- */

.form-block {
  margin: 0 0 0 10px;
  background-color: #ededed;
  min-height: calc(100vh - 20px);
  width: calc(100%-20px);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  padding: 10px;
}


.form-one-block {
  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 1.5rem
}

.form-one-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 12px;
  padding: 1rem;
  background-color: #e3e3e3;
  border: #dbdbdb 2px solid;
}

.form-item-title {
  font-size: 30px;
  font-family: 'Rubik', serif;
  text-align: left;
  font-weight: 500;
  padding: 0 0 0.5rem 0;
  border-bottom: #212529 2px solid;
}

.external-internal-buttons {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .external-internal-buttons {
    justify-content: center;
  }
}

.form-item-text {
  text-align: left;
}

.form-date-block {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.date-text {
  text-align: left;
}

.p-inputtext { /* Calendar widjet */
  background: transparent;
  font-family: 'Inter', serif;
  width: 100%;
  padding: 5px 5px;
  border: 2px solid #813630;
  border-radius: 8px;
  font-size: 20px;
  box-shadow: none;
}

.p-inputtext:focus { /* Calendar widjet */
  box-shadow: none;
}

.form-time-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 800;
}

.form-place-block {
  display: flex;
  flex-direction: column;
  gap: 1rem
}

.form-comment-block {
  display: flex;
  flex-direction: column;
  gap: 1rem
}

.form-module-block {
  display: flex;
  flex-direction: column;
  gap: 1rem
}

.selection-buttons-block {
  display: flex;
  flex-direction: row;
  gap: 1.5rem
}

@media (max-width: 767px) {
  .selection-buttons-block {
    justify-content: center;
  }
}

.arrrow-flat-icon {
  font-size: 40px;
  margin: auto 0;
  line-height: 52px;
}

.organizer-block {
display: flex;
flex-direction: column;
gap: 1rem
}

.submit-button-block {
  display: flex;
  justify-content: right;
}

.date-text-arrow {
  line-height: 34px;
}

.form-comments-block {
  margin: 10px;
}

.form-item-comments-list {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.form-item-comment-one {
  text-align: left;
}

.form-approval-one-item {
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.bold-text {
  font-weight: 600;
}

.approval-item-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: auto 0;
}

.approval-steps-title {
  text-align: left;
  padding-left: 1rem;
}

.approval-steps-title-2 {
  text-align: left;
}

.form-comments-block-for-approval {
  margin: 0px;
}

.form-item-text-no {
  text-align: left;
}

@media (max-width: 1300px) {
  .form-block {
    flex-direction: column;

  }
}

@media (max-width: 1023px) {
  .form-block {
    width: 100%;
    margin: 70px 0 0 0;
  }
}

@media (max-width: 767px) {
  .form-item-title {
    font-size: 24px;
  }
  .selection-buttons-block {
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .form-item-title {
    font-size: 20px;
  }

  .form-item-text {
    font-size: 14px;
  }

  .date-text {
    font-size: 14px;
  }

  .selection-buttons-block {
    flex-wrap: wrap;
  }
}

/* -------- Logout Confirm ------------ */

.instruction-body {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:lightgrey;
  width: 34rem;
  max-width: 95%;
  animation: ani 500ms forwards;
  border-radius: 1rem;
  padding: 1rem;
  z-index: 60;
  overflow: hidden;
}

.close-instruction-icon {
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
}

@keyframes ani {
  0% {transform: translate(-50%, 550%);}
  100% {transform: translate(-50%, -50%);}
}

.background-block {
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 50;
  opacity: 0.8;
  background-color: #000;
  animation: show-inst-back 200ms ease-in-out;
}

@keyframes show-inst-back {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.8;
  }

}

.close-instruction-icon {
  cursor: pointer;
}

.instruction-title {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  padding: 0 0 0.6rem 0;
}

.instruction-text {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem
}

.instruction-text img{
  max-width: 64px;
  padding-bottom: 16px;
}

.inst-link-block {
  text-decoration: none;
  color: #555;
}

/* ----- Month Calendar -------- */

.calendar-body-block {
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.month-calendar-body {
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.month-header-table {
  margin: 0.2rem 0 1rem 0;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.one-month-table {
  flex: 1 1 0%;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(5, minmax(0, 1fr));
  max-height: calc(100% - 39px);
}

.one-month-table-6 {
  flex: 1 1 0%;
  display: grid;
  max-height: calc(100% - 38px);
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.week-toggle-block-5 {
  padding-top: 39px;
  width: 15px;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.week-toggle-block-6 {
  padding-top: 39px;
  width: 15px;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.div-for-week-toggle {
  width: 100%;
  height: 100%;
  line-height: 188px;
  background-color: #e6e7eb;
  cursor: pointer;
  transition: 0.3s
}

.div-for-week-toggle:hover {
  cursor: pointer;
  background-color: #dbdbdb;
  transition: 0.3s
}

.month-day-body {
  display: flex;
  flex-direction: column;
  border: rgb(229 231 235) solid 1px;
  position: relative;
}

.month-day-header {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.month-day-title {
  margin-top: 1rem;
}

.month-day-text {
  margin: 3px;
  padding: 8px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 8px;
}

.month-day-text:hover {
  background-color: #dbdbdb;
  transition: 0.3s;
}

.current-day-class {
  background-color: #813630;
  color: white;
  border-radius: 8px;
}

.current-day-class:hover {
  background-color: #813630 !important;
}

.other-month-day-class {
  color: gray;
  display: none !important;
}

.month-title {
  margin: auto 0;
  font-size: 22px;
}

.month-day-events {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0 0;
  overflow-x: hidden;
}

.month-day-events::-webkit-scrollbar {
  width: 0px;
}

.month-day-event {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  padding: 3px;
  margin: 0 2px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  transition: 0.3s;
  white-space: nowrap;
}

.month-day-event-multi {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  padding: 3px 3px;
  margin-left: -2px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  transition: 0.3s;
  white-space: nowrap;
  position: absolute;
  width: calc(100% + 4px);
  height: 20.5px;
  z-index: 12;
}

.event-text {

}

.multi-event-start {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.multi-event-end {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}


.month-day-event:hover {
  background-color: #dbdbdb;
  transition: 0.3s;
}

.event-dot {
  min-width: 8px;
  height: 8px;
  border: solid transparent 1px;
  border-radius: 4px;
  color: #000;
  margin: auto 3px auto 0px;
}


@media (max-width: 1023px) {

}

@media (max-width: 767px) {
  .month-day-header {
    text-align: center;
    align-items: center;
    font-size: 12px;
  }
  .month-day-text {
    margin: 2px;
    padding: 5px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 8px;
  }
  .month-header-table {
    font-size: 14px;
  }
  .event-text {
    display: none;
  }
  .month-day-events {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    margin: 0 2px;
    pointer-events: none;
  }
  .month-title {
    font-size: 18px;
    width: 140px;
  }
}

@media (max-width: 400px) {
  .month-title {
    font-size: 14px;
    width: 110px;
  }
}



/* ----- Week Calendar -------- */

.week-body {
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.week-left-timestamps {
  display: flex;
  flex-direction: column;
  margin-top: -5px;
  gap: 40px;
}

.whole-new-week-body {
  display: flex;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 transparent;
  padding-top: 6px;
  -webkit-overflow-scrolling: touch;

}

.week-left-timestamps-item {
  font-size: 10px;
  color: #555;
  line-height: 10px;
  padding-right: 2px;
}

.one-week-table {
  flex: 1 1 0%;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(1, minmax(0, 1fr));
}


.week-title {
  margin: auto 0;
  font-size: 20px;
  position: relative;
  z-index: 2;
}

.week-header-table-cell {
  cursor: pointer;
  margin: 0 auto;
  padding: 0.4rem;
  display: flex;
  flex-direction: row;
}

.week-header-table {
  margin: 0rem 0 0.4rem 30px;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.week-day-text-number {
  font-size: 20px;
  font-weight: 600;
}

.week-day-text-name {
  font-size: 14px;
  font-weight: 500;
  margin-top: 2px;
}

.week-day-body {
  display: flex;
  flex-direction: column;
  position: relative;
}

.week-day-timestamps {
  border: rgb(229 231 235) solid 1px;
}

.week-day-one-chunck {
  font-size: 10px;
  color: #555;
  border-bottom: #e6e7eb solid 1px;
  height: 50px;
  text-align: right;
}

.week-day-one-event {
  position: absolute;
  /* background: rgba(72, 135, 225, 0.2); */
  width: 90%;
  transition: 0.3s;
  border-radius: 4px;
  padding: 4px;
}

.week-day-one-event:hover {
  background: rgba(20, 103, 218, 0.8) !important;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.week-event-text {
  text-align: left;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
}

.week-event-name {
  font-weight: 500;
}

@media (max-width: 767px) {
  .whole-new-week-body {
    position: relative;
  }

  .one-week-table {
    flex: 1 1 0%;
    display: grid;
    grid-template-columns: repeat(7, minmax(200px, 200px));
    grid-template-rows: repeat(1, minmax(200px, 200px));
  }

  .calendar-body-block {
    overflow: scroll;
  }

  .week-title {
    margin: auto -25px;
    font-size: 16px;
    width: 140px;
    overflow: hidden;
  }
  .hide-on-mobile {
    display: none;
  }

}

/* -------- Day Calendar -------- */

.day-title {
  margin: auto 0;
  font-size: 20px;
}

.day-calendar-body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.day-calendar-header {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  margin-bottom: 0.5rem;
}

.day-number {
  font-size: 36px;
  cursor: pointer;
}

.day-name {
  font-size: 22px;
  cursor: pointer;
}

.day-calendar-timestamps {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  gap: 40px
}

.bot-link {
  color: blue
}

.day-calendar-events {

}

.day-calendar {

}

.one-day-table {
  flex: 1 1 0%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

/* ---------- Login ----------- */


.main2 {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  padding: 10px;
}

.register-form-block {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}

.login-form-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.errors-message-class {
  font-size: 14px;
  color: red;
}

.left-login-block {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 300px;
}

.register-link {
font-size: 14px;
}

.register-link a{
  color: #1600c4
  }

.height-need {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #ededed;
}

.two-sides-flex {
  min-height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: white;
  max-width: 400px;
  min-width: 300px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;;
}

.enter-name-block {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

/* ------------ Profile Page ----------- */


.profile-block {
  margin: 0 0 0 10px;
  background-color: #ededed;
  min-height: calc(100vh - 20px);
  width: calc(100vw - 130px);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  padding: 10px;
  justify-content: start;
  align-items: center;

}

.profile-one-block {
  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 1.5rem;
  width: 420px;
}

.one-thing-block {
  display: flex;
  flex-direction: column;
  gap: .6rem;
  text-align: left;
}

.email-input-block {
  display: flex;
  flex-direction: row;
  gap: .6rem;
}

.email-phone-check {
  font-size: 30px;

}

.color-good {
  color: green;
  padding-top: 2px;
}

.color-warning {
  color: #bc0000;
  padding-top: 4px;
}

.confirm-otp-text {
  text-decoration: underline;
  cursor: pointer;
  line-height: 36px;
}

.tooltip-text {
  font-family: "PT Sans", sans-serif;
  background-color: #333333;
  color: #FFFFFF; 
  font-size: 16px; 
  line-height: 20px; 
  font-weight: 400; 
  text-transform: none; 
  padding: 10px 8px 8px 12px; 
  border-radius: 10px; 
  width: 190px; 
  position: absolute; 
  bottom: 120%; 
  left: 50%; 
  z-index: 1; 
  transform: translateX(-50%); 
  display: none;
  text-align: center;
}

.tooltip {
  position: absolute;
  width: 25px;
  height: 25px;
}

.tooltip-toggle:hover + .tooltip-text,
.tooltip-toggle:focus + .tooltip-text {
  display: block;
}

.annotation-profile-text {
  text-align: justify;
  padding: 15px;
  margin-top: 8px;
  border-radius: 8px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;;
}

@media (max-width: 1023px) {
  .profile-block {
    margin: 70px 0 0 0;
    min-height: calc(100vh - 90px);
    width: calc(100vw - 20px);
    padding: 10px 0 40px 0;
  }
}

@media (max-width: 767px) {
  .profile-block {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .profile-one-block {
    width: calc(100% - 20px);
    padding-right: 30px;
  }
  .tooltip-text {
    font-family: "PT Sans", sans-serif;
    background-color: #333333;
    color: #FFFFFF; 
    font-size: 16px; 
    line-height: 20px; 
    font-weight: 400; 
    text-transform: none; 
    padding: 10px 8px 8px 12px; 
    border-radius: 10px; 
    width: 190px; 
    position: absolute; 
    bottom: 120%; 
    left: 50%; 
    z-index: 1; 
    transform: translateX(-90%); 
    display: none;
    text-align: center;
  }
}

/* --------- List Page ------------ */

.list-events-block {
  margin: 0 0 0 10px;
  background-color: #ededed;
  min-height: calc(100vh - 20px);
  width: calc(100% - 10px);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  padding: 20px;
}

@media (max-width: 1023px) {
  .list-events-block {
    margin: 70px 0 0 0px;
    width: calc(100%);
    min-height: calc(100vh - 90px);
  }
}

/* -------- Events Page ---------- */

.page-name-block {
  text-align: left;
}

.list-events-block-confirming {
  gap: 1rem
}

.events-type-chosing {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 1.5rem 1rem 1.5rem 0;
}

.event-link {
  text-decoration: none;
  color: black;
  flex: 0 1 90%
}

.events-list-block {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.one-event-block {
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  padding: 1rem;
  background-color: #e3e3e3;
  border: #dbdbdb 2px solid;
  width: 100%;
  transition: 0.3s;
}

.one-event-block:hover {
  background-color: #dbdbdb;
  transition: 0.3s;
  -webkit-box-shadow: 0px 0px 10px 4px rgba(34, 60, 80, 0.3);
-moz-box-shadow: 0px 0px 10px 4px rgba(34, 60, 80, 0.3);
box-shadow: 0px 0px 10px 4px rgba(34, 60, 80, 0.3);

}


.one-event-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.5rem;

}

.one-event-name {
  font-size: 22px;
  font-weight: 500;
}

.one-event-date {

}

.one-event-time {

}

.one-event-delete {
  margin: auto 0;
  text-align: right;
  flex: 0 1 10%
}

.delete-event-icon {
  font-size: 30px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  user-select: none;
}

.delete-event-icon:hover {
  color: red;
  transition: 0.3s;
}

.no-events-block {
  margin: auto;
  padding-bottom: 100px;
 font-size: 32px;
 font-weight: 500; 
 color: #474A51
}

@media (max-width: 1023px) {
  .events-type-chosing {
    justify-content: center;
  }
}

/* -------- Approval -------- */

.approval-event-info {
  display: flex;
  flex-direction: column;
  gap: 2rem
}

.detailed-event-approval {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.approval-actions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.link-text {
  color: #1600c4;
  margin-left: 1px;
}













































/* ------------ Page Not Found ------------- */

.context {
  width: 100%;
  position: absolute;
  top:50vh;
  padding: 0 1rem;

}

.context h1{
  text-align: center;
  color: #813630;
  font-size: 50px;
}

.area{
  background: #ededed;
  width: 100%;
  height:100vh;

}

.squares{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.squares li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(129, 54, 48, 0.5);
  animation: animate 25s linear infinite;
  bottom: -150px;

}

.squares li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.squares li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.squares li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.squares li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.squares li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.squares li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.squares li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.squares li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.squares li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.squares li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }
  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }
}

.Pos {
  margin-bottom: 20px;
}






















/* ------------ Toast ----------- */


.alertmessageold {
  color: white;
  z-index: 80;
  padding: 1rem 1rem;
  position: fixed !important;
  width: 360px;
  border-radius: 12px;
  background-color: #813630;
  top: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;  opacity: 0;
}

.alertmessage-enter-active {
  opacity: 0;

}

.alertmessage-enter-done {
  opacity: 1;
  transition: opacity .5s;
}

.alertmessage-exit {
  opacity: 1;
}

.alertmessage-exit-active {
  opacity: 0;
  transition: opacity .5s;
}

.messageclose {
  cursor: pointer;
  vertical-align: middle;
}

.messagebodypair {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (max-width: 420px)  {
  .alertmessageold {
    width: calc(100% - 4rem);
  }
}