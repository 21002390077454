.myInput {
    width: 100%;
    padding: 5px 5px;
    border: 2px solid #813630;
    border-radius: 8px;
    font-size: 20px;
    font-family: 'Inter', serif;
    background: transparent
  }

textarea:focus, input:focus{
    outline: none;
  }